import styled, { createGlobalStyle } from 'styled-components';

const color = "#e55625";

export const NavContainer = styled.div`
  width: 100%;
  background-color: #fff;
  position: sticky;
  margin-bottom: 25px;
  z-index: 3;
  position: fixed;
  top: 0;
  box-shadow: -1px 5px 20px 0px #0000000f;
`;

export const Nav = styled.nav`
  display: flex;
  height: 70px;
  max-width: 100%;
  padding: 0 100px;
  margin: auto;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  svg{
    width: 100px;
  }
`;

export const NavItem = styled.li`
  font-size: 14px;
  a {
    font-weight: 600;
    color: #0000008c;
    text-decoration: none;
    &:hover {
      color: ${color};
      border-bottom: 2px solid ${color};
      padding-bottom: 3px;
    }
  }
`;

export const Navbar = styled.ul`
  display: flex;
  list-style: none;
  flex: 1;
  padding: 0;
  margin: 0;
  align-items: center;
  gap: 12px;
  @media (max-width: 550px) {
    display: none;
  }
`;

export const NavSocials = styled(Navbar)`
  justify-content: flex-end;
`

