import styled from 'styled-components';

export const Container = styled.div`
    padding: 15px 100px;
    @media (max-width: 1199px){
        padding: 15px 0;
    }
`;

export const Galeria = styled.div`
    display: flex;
    gap: 25px;
    @media (max-width: 1199px){
        flex-direction: column;
    }
`;


export const Menu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 150px;
    max-height: 500px;
    overflow: auto;
    img{
        transition: all .3s;
        cursor: pointer;
        opacity: .7;
        border-radius: 5px;
        &:hover{
            opacity: 1;
            box-shadow: -1px 2px 9px 0px #0000006e;
        }
    }
    @media (max-width: 1199px){
        width: 100%;
        flex-direction: initial;
        max-height: initial;
        img{
            width:150px;
        }
    }
`;

export const PrincipalImage = styled.div`
    width: 100%;
    height: 500px;
    background: ${({ background }) => `url(${background})`};
    background-size: cover;
    flex: 1;
    border-radius: 5px;
    @media (max-width: 1199px){
        width: auto;
        flex: 350px;
    }
`
