import React from 'react';

// import { Container } from './styles';

export default class ErrorBoundary extends React.Component {
    state = {
        error: false
    }

    componentDidCatch(error, info) {
        this.setState({ error: true })
    }

    render() {

        if (this.state.error) {

            return <div style={{ maxWidth: '550px', margin: 'auto', position: 'absolute', top: '40%', bottom: 0, left: 0, right: 0, textAlign: 'center' }}>
                <h3>Ops!</h3>
                <p>Ocorreu um erro ao tentarmos processar sua solicitação. Fique tranquilo, nós já estamos
                    trabalhando para corrigir o problema e vamos resolvê-lo logo.
                </p>
            </div>
        } else {

            return this.props.children
        }
    }
}