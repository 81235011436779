/*
  |--------------------------------------------------------------------------
  | Auth
  |--------------------------------------------------------------------------
  */

import Dashboard from "../../app/pages/admin/Dashboard";
import Login from "../../app/pages/admin/Login";
import PrintOrder from "../../app/pages/admin/Order/PrintOrder";

export default [
    // {
    //     path: '/admin/login',
    //     name: 'Login',
    //     icon: null,
    //     component: Login,
    //     private: false,
    //     exact: true,
    //     type: 'public'
    // },
    // {
    //     path: '/admin/painel',
    //     name: 'Login',
    //     icon: null,
    //     component: Dashboard,
    //     private: true,
    //     exact: true,
    //     type: 'user'
    // },
    // {
    //     path: '/admin/painel/ordens',
    //     name: 'Order',
    //     icon: null,
    //     component: Dashboard,
    //     private: true,
    //     exact: true,
    //     type: 'user',
    //     children: [
    //         {
    //             path: '/admin/painel/ordens/:code/print',
    //             name: 'Order Print',
    //             icon: null,
    //             component: PrintOrder,
    //             private: true,
    //             exact: true,
    //             type: 'user',
    //         }
    //     ]
    // }
]