import React from 'react';
import * as Styled from './styles'
import * as Layout from '../../../components/Container/styles';
import { ReactComponent as Image } from './img.svg';


export default function AboutUs() {
    return (
        <Styled.Container id="o_que_somos" background={require('./folinha.png')}>
            <Styled.ImageContainer>
                <Image />
            </Styled.ImageContainer>
            <Styled.TextsContainer>
                <h2>O que somos</h2>
                <p>Um empreendimento de inovação que reúne uma rede de profissionais  da gastronomia tradicional e do futuro.</p>
                <p>Para  dar conta de todos estes conceitos e conseguir transformar o  comer, trabalhamos em rede, contamos com os melhores chefs e  cozinheiros que encontramos por este longo e delicioso caminho.  Trabalhar em parceria e em rede é inovar, e este é o proposito do nosso  ateliê.</p>
            </Styled.TextsContainer>
        </Styled.Container>
    )
}