import React from 'react';
import api from '../../api';
import Cookie from 'js-cookie';
import auth from '../../config';
import { trackPromise } from 'react-promise-tracker';
import history from '../../history';


export default function useSession() {

  const [authenticated, setAuthenticated] = React.useState({
    logged: false,
    session: 'public'
  });

  const [rendering, setRendering] = React.useState(true);
  const [verifyEmail, setVerifyEmail] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [updateChanges, setUpdateChanges] = React.useState(false);

  React.useEffect(() => {

    const session = Cookie.get('sessionKey');
    let token = Cookie.get('token');

    let authURL = null;

    switch (session) {
      case 'customer':
        authURL = auth.authenticatedUrl;
        break;
      case 'user':
        authURL = auth.adminAuthenticatedUrl;
        break;
      default:
        token = false;
        break;
    }
    
    token ? (async () => {

      api.defaults.headers.Authorization = `Bearer ${token}`
      await api.get(authURL).then(response => {

        setUser(response.data.data);

        setAuthenticated({
          logged: true,
          session: session
        })

        setRendering(false)

      }).catch(error => {
        if (error.response.data.error && error.response.data.message === 'MAIL_VERIFICATION_REQUIRED') {

          setAuthenticated({
            logged: true,
            session: session
          })
          setVerifyEmail(true)
          setRendering(false)
          return false;

        }
      });

    })()
      : setRendering(false)

  }, [authenticated.logged, updateChanges])


  const _watch = () => {
    setUpdateChanges(Math.random())
  }
  const startSession = async (token) => {

    api.defaults.headers.Authorization = `Bearer ${token}`

    api.get(auth.authenticatedUrl).then(response => {

      setUser(response.data.data);
      Cookie.set('token', token);

    }).catch(error => {

      if (error.response.data.error && error.response.data.type === 'MAIL_VERIFY') {
        return setVerifyEmail(true)
      }

    });

  }

  const signIn = (credentials, backoffice = false) => {

    let token = backoffice ? 'user' : 'customer';
    Cookie.set('sessionKey', token);

    const data = api.post(backoffice || auth.authURL, credentials)
      .then((response) => {
        Cookie.set('token', response.data.data);
        setAuthenticated({
          logged: true,
          path: token
        })
        return response.data

      }).catch(error => error.response.data)
    trackPromise(data, 'signIn')
    return data;

  }

  const signOut = (backoffice = false) => {

    Cookie.remove('token');
    api.defaults.headers.Authorization = undefined
    window.location.href = '/';
  
  }

  return { rendering, authenticated, signIn, signOut, user, verifyEmail, startSession, _watch };
}