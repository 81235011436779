
import Home from "../../app/pages/home";

/*
|--------------------------------------------------------------------------
| Web Site
|--------------------------------------------------------------------------
*/


export default [

]