import Home from "../../app/pages/home";

/*
|--------------------------------------------------------------------------
| Web Site
|--------------------------------------------------------------------------
*/
export default [
    {
        path: '/',
        name: 'Home',
        icon: null,
        component: Home,
        private: false,
        exact: true,
        type: 'public',
    }
]