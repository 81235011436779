import React from 'react';
import * as Styled from './styles'
import * as Layout from '../../../components/Container/styles'
import { CaseModal } from '../../../components/CaseModal';

// images
import Image1 from '../../../assets/cases/case1.jpg'

export default function Cases() {

    const [modalShow, setModalShow] = React.useState(false);
    const [datacase, setDatacase] = React.useState(false)

    const cases = [
        {
            images: [
                'https://image.freepik.com/fotos-gratis/bife-de-frango-coberto-com-gergelim-branco-ervilhas-tomates-brocolis-e-abobora-em-um-prato-branco_1150-24770.jpg',
                'https://img.itdg.com.br/tdg/images/blog/uploads/2017/07/shutterstock_413580649-768x512.jpg',
                'https://fastlife.fastshop.com.br/wp-content/uploads/2021/07/shutterstock_739787011.jpg'
            ],
            title: "Baixo Centro",
            dates: [2011, 2012, 2013],
            office: "Produtora e Cuidadora",
            text: "Advogada,  gastróloga pela Universidade Anhembi Morumbi,  especializada em Ecogastronomia, cursando Pós Graduação em Gestão Ambiental na UNIP,  produtora de eventos culturais e sustentáveis onde cursou,  Gestão e Produção Cultural na ESPM, porta voz e membro do Movimento Slow Food - Comunidade Cultura no Prato SP.",
            bullets: [
                "O que é/foi  o Festival  BaixoCentro?",
                "Baixo Centro",
                "Festival Baixo Centro"
            ]

        }
    ]

    return (
        <Layout.Container>
            <Styled.Container id="nossos_cases">
                <h2>Nossos Cases</h2>
                <h6>Selecione uma classificação de case para ver</h6>
                <Styled.CardsContainer>
                    {
                        cases.map((item, index) =>
                            <Styled.Card background={item.images[0]} onClick={() => { setModalShow(true); setDatacase(item) }} key={index}>
                                <Styled.Texts>
                                    <h4>{item.title}</h4>
                                    <p>Clique para ver mais</p>
                                </Styled.Texts>
                            </Styled.Card>
                        )
                    }
                </Styled.CardsContainer>
            </Styled.Container >
            <CaseModal show={modalShow} onHide={() => setModalShow(false)} datacase={datacase} />
        </Layout.Container>
    )
}