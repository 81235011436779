import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 150px;
    background: ${({ background }) => `url(${background})` || ''};
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    background-position-y: -100px;
    h2{
        font-weight: 600;
        opacity: .95;
        margin-bottom: 50px;
        text-align: center
    }
    @media(max-width:768px){
        margin-top: 50px;
        h2{
            text-align:initial
        }
    }
    @media(max-width:768px){
        margin-top: 50px;
        background: transparent;
        padding: 15px 25px;
        h2{
            text-align:initial
        }
    }
`

export const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 0 auto;
    @media(max-width:768px){
        gap: 20px;
    }
`

export const IconContainer = styled.div`
    padding: 25px;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background-color: white;
    border-radius: 50%;
    margin-bottom: 25px;
    @media(max-width:768px){
        width: 90px;
        height: 90px;
    }
`

export const Card = styled.div`
    background-color: #f6f8f3;
    fill: #d3dbc4;
    &:nth-child(2n){
        background-color: #d3dbc4;
        /* ${IconContainer}{
            fill: #f6f8f3;
        } */
    }
    padding: 30px;
    border-radius: 25px;
    text-align: center;
    width: 300px;
    max-height: 100%;
    @media(max-width:768px){
        width: 150px;
        padding: 15px;
        h4{
            font-size: 1.2em;
        }
    }
    
`

export const Texts = styled.div`
    p{
        font-size: 13px;
    }
`