import styled from 'styled-components'

export const Container = styled.div`
    padding: 50px 100px;
    background-color: rgba(167,184,137,50%);
    padding: 0 200px;
    gap: 15px;
    display: flex;
    margin-top: 100px;
   @media(max-width:1200px){
       padding: initial;
   }
    @media(max-width:768px){
        padding: 50px 25px;
    }
`

export const ImageContainer = styled.div`
    align-self: end;
    flex: 1;
    padding: 100px;
    img{
        width:100%;
    }
    @media(max-width:768px){
        display: none;
   }
`

export const TextsContainer = styled.div`
    padding: 50px;
    flex: 1 1 200px;
    text-align:justify;
    align-self: center;
    font-weight: 500;
    h2{
        font-weight: 600;
        opacity: .95;
        margin-bottom: 0px;
    }
    h6{
        margin-bottom: 50px;
        color: #6b7c3d;
    } 
    @media(max-width:768px){
        padding: initial;
    }
`