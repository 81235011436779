import styled from 'styled-components'



export const Container = styled.div`
    margin-top: 150px;
    h2{
        font-weight: 600;
        opacity: .95;
    }
    
`

export const ClippingContainer = styled.div`
    width: 100%;
    &:nth-child(odd){
        justify-content: flex-end;
        display: flex;
        text-align:right;
        
    }
    
`

export const Clipping = styled.div`
    margin-top: 100px;
    max-width: 1000px;

`

export const Title = styled.h3`
    font-size: 4em;
    font-weight: 800;
    color: #FFDBCC;
    letter-spacing: 7px;
    margin-bottom: -35px;
`

export const Date = styled.h4`
    font-size: 2em;
    font-weight: 800;
    color: #777;
`

export const Office = styled.span`
    font-weight: 600;
    color: #777;
`

export const Text = styled.p`
    margin-top:25px;
`

export const Bullets = styled.ul`
    padding: 0;
    list-style-type: none;
    margin-top: 25px;
    li{
        font-weight: 600;
        color: #FFDBCC;
        padding: 5px 5px;
        cursor: pointer;
        position: relative;
        width: max-content;
        margin-bottom: -10px;
        transition: color .3s;
        ::before{
            z-index: -1;
            position: absolute;
			top: 5;
			left: 0;
			overflow: hidden;
			padding: 10px 0;
			width: 0;
            background-color: #FFDBCC;
			content: attr(data-hover);
			transition: width 0.5s;
            height: max-content;
        }   
        &:hover{
            color: white;
            ::before{
				width: 100%;
			}
        }
    }
`
