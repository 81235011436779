import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import * as Styled from './styles'

export const CaseModal = (props) => {

    const [selectedImage, setSelectedImage] = React.useState(false);

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Cases
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Styled.Container>
                    {
                        props.datacase &&
                        <Styled.Galeria>
                            <Styled.PrincipalImage background={selectedImage || props.datacase.images[0]} />
                            <Styled.Menu>
                                {
                                    props.datacase.images && props.datacase.images.map((image, index) =>
                                        <img src={image} alt={image} key={index} onClick={() => setSelectedImage(image)} />
                                    )
                                }
                            </Styled.Menu>
                        </Styled.Galeria>
                    }

                    <h4>{props.datacase.title}</h4>
                    <p>
                        Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                        dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                        consectetur ac, vestibulum at eros.
                    </p>
                </Styled.Container>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}

