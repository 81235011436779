import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';

import website from './Routes/website';
import auth from "./Routes/auth";
import painel from "./Routes/painel";
import admin from './Routes/admin';
import history from '../history';
import Error404 from '../app/pages/errors/404';
import { SessionContext } from './Session/SessionContext';
import { useSelector } from 'react-redux';

function makeRoutes(routes) {

    let routeList = [];

    for (let i = 0; i < routes.length; i++) {
        for (let j = 0; j < routes[i].length; j++) {
            routeList.push(routes[i][j])
        }
    }

    const RouteChildren = routeList.filter(route => route.children !== undefined).map(route => route.children);

    let childrenList = [];
    let allChildren = [];

    for (let i = 0; i < RouteChildren.length; i++) {
        for (let j = 0; j < RouteChildren[i].length; j++) {
            childrenList.push(RouteChildren[i][j])
        }
    }

    let mapChildren = (child) => {
        allChildren.push(child)
        child.children && child.children.forEach(mapChildren)
    }

    childrenList.forEach(mapChildren)

    return [...routeList.map(route => route), ...allChildren]

}


function AppRoute({ isPrivate, path, ...rest }) {

    const { authenticated, rendering, verifyEmail } = React.useContext(SessionContext);
    const routeSession = useSelector(state => state.session);

    if (rendering) return null;

    if (isPrivate && !authenticated.logged && history.location.pathname === '/painel') return <Redirect to={'/login'} />
    if (isPrivate && !authenticated.logged && history.location.pathname === '/admin/painel') return <Redirect to={'/admin/login'} />


    if (authenticated.logged && routeSession.nextRoute && routeSession.nextRoute.name === 'Pagamento' && history.location.pathname === '/login') return <Redirect to={'/checkout?track='+routeSession.nextRoute.state} />

    if (authenticated.logged && authenticated.session === 'customer' && history.location.pathname === '/login') return <Redirect to={'/'} />
    if (authenticated.logged && authenticated.session === 'user' && history.location.pathname === '/admin/login') return <Redirect to={'/admin/painel'} />

    if (verifyEmail) {

        if (verifyEmail && history.location.pathname.includes('painel') || history.location.pathname.includes('contributions')) return <Redirect to={'/verify'} />
    }

    if (!verifyEmail && authenticated) {

        if (history.location.pathname === '/verify') return <Redirect to={'/painel'} />

    }
    return <Route {...rest} />

}


const AppRoutes = ({ setLayout, pageConfig }) => {

    const { authenticated, rendering } = React.useContext(SessionContext);

    return !rendering &&
        <Switch>
            {
                routes.map(ViewRoute => {

                    if (ViewRoute.type === authenticated.session || ViewRoute.type === 'public') {
                        return <AppRoute
                            key={ViewRoute.path}
                            isPrivate={ViewRoute.private}
                            exact={ViewRoute.exact}
                            path={ViewRoute.path}
                            render={(props) => <ViewRoute.component pageConfig={pageConfig} layout={setLayout} {...props} />}
                        />
                    }
                })
            }
            <Route><Error404 layout={setLayout} /></Route>
        </Switch>
}

export const routes = makeRoutes([auth, website, admin, painel]);

export default AppRoutes;
