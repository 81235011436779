import React, { useEffect } from 'react';
import AboutUs from './aboutUs';
import OurHistory from './ourHistory';
import Profile from './profile';
import WeDo from './weDo';
import Cases from './cases'
import Clipping from './clipping';

export default function Home({ layout, pageConfig }) {
    useEffect(() => {
        layout('SiteLayout')
        pageConfig({
            pageDescription: 'React',
            pageTitle: 'Cazita',
        })
    }, [layout, pageConfig])



    return (
        <React.Fragment>
            <OurHistory />
            <AboutUs />
            <WeDo />
            <Profile />
            <Cases />
        </React.Fragment>
    )
}