import styled from 'styled-components'

export const Container = styled.div`
    padding: 50px 100px;
    background: ${({ background }) => `url(${background})` || ''};
    background-size: cover;
    background-position-y: center;
    gap: 15px;
    border-radius:25px;
    display: flex;
    width: 100%;
    @media(max-width:768px){
        margin-top: 50px;
        flex-direction: column-reverse;
        padding: 50px 25px;
    }
`

export const ImageContainer = styled.div`
    position: relative;
    flex: 1 1 200px;
    @media(max-width:768px){
        display: none;
    }
    img{
        width:100%;
        position: absolute;
        right: -30px;
        bottom: 0;
        
    }
`

export const TextsContainer = styled.div`
    padding: 50px;
    flex: 1 1 200px;
    align-self: center;
    font-weight: 500;
    h2{
        font-weight: 600;
        opacity: .95;
        margin-bottom: 50px;
    }
    @media(max-width:768px){
       padding:initial;
    }
`