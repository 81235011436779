import React from 'react';

function Error404({ layout }) {

  React.useEffect(() => layout('ErrorLayout'));
  
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      <h4>404 | Not found</h4>
    </div>
  );
}

export default Error404;