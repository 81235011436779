import { createGlobalStyle } from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/css/components/form.css'
// import "./resources/fonts/line-awesome-1.3.0/1.3.0/css/line-awesome.min.css";

const GlobalStyle = createGlobalStyle`

  html, body, #root{ 
    height: 100%; 
    box-sizing:border-box; 
    font-family:"Montserrat"; 
    scroll-behavior: smooth;
  }
  
  :target {
    scroll-margin-top: 12em;
  }

`;

export default GlobalStyle;
