import React from 'react';
import { Link } from 'react-router-dom'
import { SocialIcon } from 'react-social-icons'
import '../../../styles/css/webSite/footer.css'

export default function Footer() {
    return (
        <footer className="bg-dark siteFooter text-white">
            <h3>Cazita</h3>
            <hr />
            {/* <div className="siteMap">
                <Link className="link" to="/">Início</Link>
                <Link className="link" to="/treinamentos">Treinamentos</Link>
                <Link className="link" to="/facilitador">Facilitador DISC</Link>
                <Link className="link" to="/#">Contato</Link>
            </div> */}
            {/* <div className="siteMap">
                <a className="link text-muted" href="mailto:contato@propositomaior.com.br">
                    <strong>contato@propositomaior.com.br</strong>
                </a>
            </div> */}
            {/* <div className="siteMap">
                <a className="link" href="https://www.propositomaior.com.br">
                    <strong>Um produto da Propósito MAIOR®️</strong>
                </a>
            </div> */}
            {/* <div className="siteMap icons">
                <a className="iconLink" target="_blank" href="https://www.facebook.com/camallebr">
                    <SocialIcon
                        className="icon"
                        network="facebook"
                        bgColor="#fff"
                        label="Our portfolio" />
                </a>
                <a className="iconLink" target="_blank" href="https://www.instagram.com/camallebr/">
                    <SocialIcon
                        network="instagram"
                        bgColor="#fff"
                        label="Our portfolio" />
                </a>
                <Link className="iconLink" target="_blank" to="https://www.linkedin.com/company/camalle">
                    <SocialIcon
                        className="icon"
                        network="linkedin"
                        bgColor="#fff"
                        label="Our portfolio" />
                </Link>
            </div> */}
            <div className="mt-3">
                {/* <small className="text-muted mt-5 mr-4">Camalle 2021</small> */}
                <small className="text-muted mt-5">
                    Desenvolvido por:
                    <a href="#" className="ml-2" style={{ color: "white" }}>
                        <b>Luan Carmo</b>
                    </a>
                </small>
            </div>
            <p className="mt-5">
                Em Desenvolvimento
            </p>
        </footer>
    )
}