import styled from 'styled-components'

export const Container = styled.div`
    margin-top: 150px;
    text-align: right;
   h2{
        font-weight: 600;
        opacity: .95;
    }
    h6{
        margin-bottom: 50px;
        color: #6b7c3d
    }
`

export const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
    margin: 0 auto;
`

export const Texts = styled.div`
    transition: all .3s;
    opacity: 0;
    color: white;
    background-color: rgba(0,0,0,20%);
    padding: 10px;
    position: relative;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    bottom: -217px;
    backdrop-filter: blur(2px);
    h4,p{
        transition: all .3s;
        position: relative;
        bottom: -15px;

    }
    h4{
        margin-bottom: 0;
    }
    p{
        font-size: 13px;
    }
`

export const Card = styled.div`
    transition: all .3s;
    background: ${({ background }) => background ? `url(${background})` : "#819A56"};
    background-repeat: no-repeat;
    background-size:cover ;
    border-radius: 25px;
    text-align: center;
    width: 300px;
    max-height: 100%;
    height: 300px;
    cursor: pointer;
    position: relative;
    top: 0;
    &:hover{
        transition: all .3s;
        top: -15px;
        box-shadow: 0px 5px 13px 2px #00000070;
        ${Texts}{
            transition:all .3s;
            opacity: 1;
            h4,p{
                transition:all .3s;
                bottom: -10px;
            }
        }
    }
    @media(max-width:1200px){
        width: 200px;
        height: 200px;
        ${Texts}{
            bottom: -117px;

        }
    }
    @media(max-width:425px){
        width: 100%;
    }
`

export const IconContainer = styled.div`
    width: 150px;
    height: 150px;
    margin: 0 auto;
    background-color: white;
    border-radius: 50%;
    margin-bottom: 25px;
`

