import React from 'react';
import * as Styled from './styles'
import * as Layout from '../../../components/Container/styles'

import { ReactComponent as Image } from './Image.svg';

export default function OurHistory() {
    return (
        <Layout.Container>
            <Styled.Container id="inicio">
                <Styled.TextsContainer>
                    <h2>Um pouco da nossa história</h2>
                    <p>Comer é um ato sistêmico e por isso devemos dar valor a todos os envolvidos  na cadeia produtiva, desde o plantio até o descarte de resíduos.</p>
                    <p>Nossa missão é trabalhar esta informação de todas as perspectivas possíveis  dentro da gastronomia.</p>
                    <p>Como forma de sensibilização, priorizamos o comer com todos os sentidos,  usando de muita criatividade e sensibilidade.</p>
                    <p>Textura, forma, cor, cheiro, temperatura e sabor.</p>
                    <p>Sim, comer pode ser uma experiência.</p>
                </Styled.TextsContainer>
                <Styled.ImageContainer>
                    <Image />
                </Styled.ImageContainer>
            </Styled.Container>
        </Layout.Container>

    )
}