import React from "react";
import bgLogin from '../images/imgs/login.jpg';

export default function AuthLayout({ children }) {
    return (
        <div className="login" style={{ overflow: 'auto', margin: "0 auto" }}>
            <section className="loginArea my-0 mx-auto">
                <div className="content">
                    {children}
                </div>
            </section>
        </div>
    )
}