import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
export const Title = styled.h1`
  font-size: 50px;
  @media (max-width: 820px){
    font-size: 30px !important;

  }
  opacity: 0.85;
  font-weight: 600;
`;
export const SubTitle = styled.p`
  opacity: 0.85;
`;
