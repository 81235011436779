import styled from 'styled-components'

export const Container = styled.div`
    background-color: rgba(167,184,137,50%);
    gap: 15px;
    border-radius:25px;
    display: flex;
    width: 100%;
    min-height: 555px;
    font-weight: 500;
    
   
`

export const ImageContainer = styled.div`
    position: relative;
    flex: 1 1 200px;
    align-self: bottom;
    @media(max-width:1297px){
        display: none;
    }
    svg{
        .left_hand{
            transform-origin: left;
            animation: mix 1.5s infinite alternate-reverse;
            @keyframes mix {
                from { transform: rotate(3.2deg); }
                to {  transform: rotate( 0deg); }
            }
        }
        .head{
            transform-origin: center;
            animation: mix 2s infinite alternate-reverse;
            @keyframes mix {
                from { transform: rotate(-1deg); }
                to {  transform: rotate( 1deg); }
            }
        }
        .mouth{
            transform-origin: bottom;
            animation: smile 1s infinite alternate-reverse;
            @keyframes smile {
                from { transform: scale(1); }
                to {  transform: scale(1.01); }
            }
        }

    
    }
`

export const TextsContainer = styled.div`
    padding: 50px;
    flex: 1 1 200px;
    text-align:justify;
    align-self: center;
    h2{
        font-weight: 600;
        opacity: .95;
        margin-bottom: 50px;
    }
`

