import React from 'react';
import * as Styled from './styles'
import * as Layout from '../../../components/Container/styles'

export default function Profile() {
    return (
        <Styled.Container id="cassia_cazita" >
            <Styled.TextsContainer>
                <h2>Cassia Cazita</h2>
                <h6>Produtora e Chef de Ecozinha</h6>
                <p>Advogada,  gastróloga pela Universidade Anhembi Morumbi,  especializada em Ecogastronomia, cursando Pós Graduação em Gestão Ambiental na UNIP,  produtora de eventos culturais e sustentáveis onde cursou,  Gestão e Produção Cultural na ESPM, porta voz e membro do Movimento Slow Food - Comunidade Cultura no Prato SP.</p>
                <p>Empreendedora no Cazita Atelie de Delícias, onde desde de 2011 cria , produz e realiza consultoria na area de alimentação focados em cultura e sustentabilidade.</p>
            </Styled.TextsContainer>
            <Styled.ImageContainer>
                <img src={require('../../../assets/profile.png')} alt="" />
            </Styled.ImageContainer>
        </Styled.Container>
    )
}