import styled from "styled-components";

export const Container = styled.div`
display:inline-block;
opacity: 0;
transition: all .5s;
  ${props => props.fullContent &&
  `
  width:100%;
  height: 100%;
  position:fixed;
  z-index:999999;
  top: 0;
  right: 0;
  opacity: 1;
  display:flex;
  justify-content: center;
  align-items:center;
  background-color: rgba(255 ,255 ,255, 0.3);
  div
`}

`;
