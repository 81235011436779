import React from 'react';
import * as Styled from './styles'
import { ReactComponent as Icon1 } from '../../../assets/Icons/icon_1.svg';
import { ReactComponent as Icon2 } from '../../../assets/Icons/icon_2.svg';
import { ReactComponent as Icon3 } from '../../../assets/Icons/icon_3.svg';
import { ReactComponent as Icon4 } from '../../../assets/Icons/icon_4.svg';
import { ReactComponent as Icon5 } from '../../../assets/Icons/icon_5.svg';
import { ReactComponent as Icon6 } from '../../../assets/Icons/icon_6.svg';


export default function WeDo() {
    const cards = [
        {
            title: "Catering",
            text: "Serviço de buffet",
            icon: <Icon1 />
        },
        {
            title: "Consultoria",
            text: "Consultoria em Ecogastronomia",
            icon: <Icon2 />
        },
        {
            title: "Palestras",
            text: "Palestras ecursos focados em ecogastronomia",
            icon: <Icon3 />
        },
        {
            title: "Criação",
            text: "Criação de conteúdo para gastronomia",
            icon: <Icon4 />
        },
        {
            title: "Eventos",
            text: "Produção de eventos culturais e sustentáveis",
            icon: <Icon5 />
        },
        {
            title: "Experiências",
            text: "Serviço de buffet",
            icon: <Icon6 />
        },

    ]
    return (

        <Styled.Container id="o_que_fazemos" background={require('./bg-card.png')}>
            <h2>O que fazemos</h2>
            <Styled.CardsContainer>
                {
                    cards.map((card, index) =>
                        <Styled.Card key={index}>
                            <Styled.IconContainer>
                                {card.icon}
                            </Styled.IconContainer>
                            <Styled.Texts>
                                <h4>{card.title}</h4>
                                <p>{card.text}</p>
                            </Styled.Texts>
                        </Styled.Card>
                    )
                }
            </Styled.CardsContainer>
        </Styled.Container>
    )
}