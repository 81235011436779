import React from 'react';

import * as Styled from './styles'

import { ReactComponent as Logo } from '../../assets/logo/Logo.svg'

export default function NavbarIndex() {
    return (
        <Styled.NavContainer>
            <Styled.Nav>
                <a href="#inicio">
                    <Logo />
                </a>
                <Styled.Navbar>
                    <Styled.NavItem>
                        <a href={'/#inicio'}>Início</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a href={'/#o_que_somos'}>O que somos</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a href={'/#o_que_fazemos'}>O que fazemos</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a href={'/#cassia_cazita'}>Cassia Cazita</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a href={'/#nossos_cases'}>Nossos Cases</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a href={'/#clipping'}>Clipping</a>
                    </Styled.NavItem>
                </Styled.Navbar>
                <Styled.NavSocials>
                    <Styled.NavItem>
                        <a target="_blank" href={'https://www.facebook.com'}>Facebook</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a target="_blank" href={'https://www.instagram.com'}>Instagram</a>
                    </Styled.NavItem>
                    <Styled.NavItem>
                        <a target="_blank" href={'/#inicio'}>Facebook</a>
                    </Styled.NavItem>
                </Styled.NavSocials>
            </Styled.Nav>
        </Styled.NavContainer>
    )
}