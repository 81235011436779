import React from 'react';
import website from '../../start/Routes/website';
import Footer from '../components/Footer';
import NavbarIndex from '../components/Navbar/NavbarIndex'

import * as Styled from './styles';

export default function AppLayout({ children }) {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [children])

    return (
        <React.Fragment>
            <NavbarIndex />
            <Styled.LayoutContainer>
                {children}
            </Styled.LayoutContainer>
            <Footer />
        </React.Fragment>
    )
}

